import { Paths } from '../../constants/paths';
import { Route } from "react-router-dom";
import BeneficiariesList from './BeneficiariesList';
import SingleBeneficiary from './SingleBeneficiary';
import { FormComponentProps } from '../FormComponentProps';

export default function Beneficiaries(props: FormComponentProps) {
  return (
    <>
      <Route path={Paths.Beneficiaries} exact={true} render={(routeProps) => (
          <BeneficiariesList form={props.form} />
        )} />
      <Route path={`${Paths.Beneficiaries}/:idx`} exact={true} render={(routeProps) => (
          <SingleBeneficiary form={props.form} />
        )} />
    </>
  )
}