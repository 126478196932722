import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  gridItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});