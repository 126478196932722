import { Paths } from '../../constants/paths';
import { Route } from "react-router-dom";
import ChildrenList from './ChildrenList';
import SingleChildInfo from './SingleChildInfo';
import { FormComponentProps } from '../FormComponentProps';

export default function ChildrenInfo(props: FormComponentProps) {
  return (
    <>
      <Route path={Paths.ChildrenInfo} exact={true} render={(routeProps) => (
          <ChildrenList form={props.form}/>
        )} />
      <Route path={`${Paths.ChildrenInfo}/:idx`} exact={true} render={(routeProps) => (
          <SingleChildInfo form={props.form} />
        )} />
    </>
  )
}
