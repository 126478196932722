import { ChildInfoType, IntakeType } from "../context/IntakeContext";

export function hasSpouse(data: IntakeType) {
  return data.maritalStatus === 'married' || data.maritalStatus === 'separated';
}

export function hasChildren(data: IntakeType) {
  return data.hasChildren === 'yes'
}

export function childFullName(child: ChildInfoType) {
  if (child.middleName) {
    return `${child.firstName} ${child.middleName} ${child.lastName}`;
  }

  return `${child.firstName} ${child.lastName}`;
}