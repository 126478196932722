import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyB7bMKHieQ9fanwdCfNabf60Mfl1jmpPsA",
  authDomain: "easywillmaker.firebaseapp.com",
  projectId: "easywillmaker",
  storageBucket: "easywillmaker.appspot.com",
  messagingSenderId: "931778094905",
  appId: "1:931778094905:web:bc22698ac1657f2f9f6ae8",
  measurementId: "G-DKVKHV1ENF"
};
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;