import { useForm } from "react-hook-form";
import { Route } from "react-router-dom"
import { useFormData, IntakeType } from '../context/IntakeContext';
import { hasChildren, hasSpouse } from '../helpers/modelHelpers';

import { Paths } from '../constants/paths';

import PersonalInfo1 from './PersonalInfo/PersonalInfo1';
import PersonalInfo2 from './PersonalInfo/PersonalInfo2';
import ChildrenInfo from './ChildrenInfo/ChildrenInfo'
import CashBequests from './CashBequests/CashBequests';
import Beneficiaries from './Beneficiaries/Beneficiaries';
import HealthcarePreferences from './HealthcarePreferences';
import RelationshipOrPerson from './RelationshipOrPerson';
import Person from './Person';

import { Box, Container, makeStyles } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

export default function Form() {
  const theme = useTheme();
  const useStyles = makeStyles({
    outerBox: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%',
    }
  });
  const classes = useStyles();
  
  const { currentIntake } = useFormData();

  const form = useForm<IntakeType>({
    defaultValues: currentIntake
  });

  return (


    // <Router>
<>
<Box py={3} className={classes.outerBox}>
    <Container maxWidth="lg">
    <Route path={Paths.PersonalInfo1} render={(props) => (
        <PersonalInfo1 form={form} />
      )} />
      <Route path={Paths.PersonalInfo2} render={(props) => (
        <PersonalInfo2 form={form} />
      )} />
      <Route path={Paths.ChildrenInfo} render={(props) => (
        <ChildrenInfo form={form} />
      )} />

      <Route path={Paths.CashBequests} render={(props) => (
        <CashBequests form={form} />
      )} />

      <Route path={Paths.Beneficiaries} render={(props) => (
        <Beneficiaries form={form} />
      )} />

      <Route path={Paths.PersonalRepresentative} exact={true} render={(props) => (
        <RelationshipOrPerson
        dataKey="personalRepresentative"
        nextPath={Paths.PersonalRepresentativeSuccessor}
        form={form}
       
        header="Who would you like to name as the personal representative to administer your estate?"/>
      )} />

       <Route path={Paths.PersonalRepresentativeSuccessor} exact={true} render={(props) => (
        <RelationshipOrPerson
          dataKey="personalRepresentativeSuccessor"
          nextPath={hasChildren(currentIntake) ? Paths.ChildGuardian : Paths.HealthcareProxy}
          form={form}
         
          header="If the forgoing named personal representative(s) do not survive or are unable to act, who would you like to name as successor personal representatives?"/>
      )} />

      { hasChildren(currentIntake) &&
        <Route path={Paths.ChildGuardian} exact={true} render={(props) => (
          <Person
            dataKey="childGuardian"
            nextPath={Paths.ChildGuardianSuccessor}
            form={form}
           
            header={ hasSpouse(currentIntake) ? "Who would you like to name as guardian for your minor children in the event that your spouse predeceases you?" : "Who would you like to name as guardian for your minor children?" }
            />
        )} />
      }
      <Route path={Paths.ChildGuardianSuccessor} exact={true} render={(props) => (
        <Person
          dataKey="childGuardianSuccessor"
          nextPath={Paths.FinancialAgent}
          form={form}
         
          header="In the event that this individual does survive or is unable to act, who would you like to name as successor guardian for any minor children?"/>
        )} />
      

      <Route path={Paths.HealthcarePreference} exact={true} render={(props) => (
        <HealthcarePreferences form={form} />
      )} />

      <Route path={Paths.HealthcareProxy} exact={true} render={(props) => (
        <RelationshipOrPerson
          dataKey="healthcareProxy"
          nextPath={Paths.HealthcareProxySuccessor}
         form={form} 
         header="Who would you like to name as your agent to make healthcare decisions in the event that you cannot make them on your own behalf?"
         />
      )} />

      <Route path={Paths.HealthcareProxySuccessor} exact={true} render={(props) => (
        <RelationshipOrPerson
          dataKey="healthcareProxySuccessor"
          nextPath={Paths.HealthcareProxyLastResort}
         form={form} 
         header="In the event that this individual does not survive or is unable to serve, who would you like to name as successor healthcare agent?"
         />
      )} />

      <Route path={Paths.HealthcareProxyLastResort} exact={true} render={(props) => (
        <RelationshipOrPerson
          dataKey="healthcareProxyLastResort"
          nextPath={Paths.FinancialAgent}
         form={form} 
         header="In the event that both the forgoing individuals do not survive or are unable to act, who would you like to name as your healthcare agent?"
         />
      )} />

      <Route path={Paths.FinancialAgent} exact={true} render={(props) => (
        <RelationshipOrPerson
          dataKey="financialProxy"
          nextPath={Paths.FinancialAgentSuccessor}
          form={form}
         
          header="Who would you like to name as your agent to handle your financial affairs in the event that you are unable to do so yourself?"
          />
      )} />

      <Route path={Paths.FinancialAgentSuccessor} exact={true} render={(props) => (
        <RelationshipOrPerson
          dataKey="financialProxySuccessor"
          nextPath={Paths.FinancialAgentLastResport}
          form={form}
         
          header="In the event that this individual does not survive or is unable to serve, who would you like to name as successor agent?"
          />
      )} />
      <Route path={Paths.FinancialAgentLastResport} exact={true} render={(props) => (
        <RelationshipOrPerson
          dataKey="financialProxyLastResort"
          nextPath={Paths.Conclusion}
          form={form}
         
          header="In the event that both the forgoing individuals do not survive or are unable to act, who would you like to name as your agent?"
          />
      )} />
    </Container>
  </Box>
</>
    // </Router>
  );
}