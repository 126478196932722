import { IntakeProvider } from '../context/IntakeContext';
import IntakeLayout from './IntakeLayout';
import React from 'react';
import firebase from '../lib/firebase';
import { useHistory } from 'react-router';

function Intake() {
  const history = useHistory();

  React.useEffect(() => {
    if (firebase.auth().currentUser === null) {
      history.push('/login');
    }
  }, [history])

  return (
  <IntakeProvider>
    <IntakeLayout />
  </IntakeProvider>
  )
}

export default Intake;