import { useParams, useHistory } from "react-router-dom"
import { FormComponentProps } from "../FormComponentProps";
import { Button, Grid, TextField, InputAdornment, Select, MenuItem, Card, CardHeader, Divider, CardContent, Box, FormControl, InputLabel } from '@material-ui/core';
import { Controller, useForm } from "react-hook-form";
import { hasSpouse, childFullName, hasChildren } from '../../helpers/modelHelpers';
import { Beneficiary } from "../../context/IntakeContext";
import { useState } from 'react';
import { Paths } from '../../constants/paths';
import { useStyles } from '../styles';
import { useFormData } from '../../context/IntakeContext';

export default function SingleBeneficiary(props: FormComponentProps) {
  const { currentIntake, saveIntake } = useFormData();
  let { idx } = useParams() as any;
  const zeroIndex = parseInt(idx || 1, 10) - 1;
  currentIntake.beneficiaries = currentIntake.beneficiaries || [];
  const currentValues = currentIntake.beneficiaries[zeroIndex] || {};

  const hasSpouseOrChildren = hasSpouse(currentIntake) || hasChildren(currentIntake);

  const form = useForm<Beneficiary>({
    defaultValues: currentValues
  });

  let calculatedRelationship = currentValues.relationship || '' as '' | 'spouse' | 'child' | 'other';

  if (!hasSpouseOrChildren) {
    calculatedRelationship = 'other';
  }

  const [relationship, setRelationship] = useState(calculatedRelationship);

  const history = useHistory();

  const onSubmit = form.handleSubmit(async (data) => {
    currentIntake.beneficiaries[zeroIndex] = { 
      ...data,
      relationship
    }

    await saveIntake({ beneficiaries: currentIntake.beneficiaries });
    history.push(Paths.Beneficiaries)
  });
  const handleRelationshipChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRelationship(event.target.value as 'spouse' | 'child' | 'other');
  }

  const classes = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title="Who should inherit your estate?"
          // subheader="Let's start by getting your basic information"
        />
        <Divider />
        <CardContent>
        <Grid container spacing={6} wrap="wrap">
            {hasSpouseOrChildren &&
              <Grid item md={12} className={classes.gridItem}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="chooseRelationship">Relationship...</InputLabel>
                  <Select name="chooseRelationship" fullWidth={true} onChange={handleRelationshipChange} value={relationship}>
                    { hasSpouse(currentIntake) && 
                    <MenuItem value="spouse">My Spouse</MenuItem>
                    }
                    { hasChildren(currentIntake) && 
                    <MenuItem value="child">My Child</MenuItem>
                    }
                    <MenuItem value="other">Someone Else</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            }

            { (relationship === 'spouse') && 
              <Grid item md={12} className={classes.gridItem}>
                <input type="hidden" value="spouse" {...form.register("relationship")} />
                <Controller name="percentage"
                  control={form.control}
                  rules={{ required: 'This field is required' }}
                  defaultValue={undefined}
                  render={({ field }) => <TextField {...field} 
                    label="Percent"
                    type="number"
                    inputProps={{ min: "00.1", step: "0.01" }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    fullWidth={true}
                    error={!!form.formState.errors.percentage}
                    helperText={form.formState.errors.percentage?.message}
                    />}
                />
              </Grid>
            }

            { (relationship === 'child') &&
              <>
              <input type="hidden" value="child" {...form.register("relationship")} />
              <Grid item md={12} className={classes.gridItem}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="childName">Which Child?</InputLabel>
                  <Controller
                    name="childName"
                    control={form.control}
                    rules={{ required: 'This field is required'}}
                    defaultValue={undefined}
                    render={({ field }) => <Select {...field} fullWidth={true} error={!!form.formState.errors.childName} value={field.value || ''}>
                      {
                      (currentIntake.children || []).map(child => { return (
                        <MenuItem value={childFullName(child)}>{childFullName(child)}</MenuItem>)
                      }) 
                      }
                    </Select>
                  }/>
                </FormControl>
              </Grid>
              <Grid item md={12} className={classes.gridItem}>
                <Controller name="percentage"
                  control={form.control}
                  rules={{ required: 'This field is required' }}
                  defaultValue={undefined}
                  render={({ field }) => <TextField {...field} 
                    label="Percent"
                    type="number"
                    inputProps={{ min: "00.1", step: "0.01" }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    fullWidth={true}
                    error={!!form.formState.errors.percentage}
                    helperText={form.formState.errors.percentage?.message}
                    />}
                />
              </Grid>
              </>
            }

            { (relationship === 'other') && 
            <>
              <input type="hidden" value="other" {...form.register("relationship")} />
              <Grid  item md={4} xs={12} className={classes.gridItem}>
                <Controller name="firstName"
                  control={form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} 
                    label="First Name"
                    fullWidth={true}
                    error={!!form.formState.errors.firstName}
                    helperText={form.formState.errors.firstName?.message}/>}
                />
              </Grid>
              <Grid  item md={4} xs={12} className={classes.gridItem}>
                <Controller name="middleInitial"
                  control={form.control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field}
                    label="Middle Initial" 
                    fullWidth={true}
                    error={!!form.formState.errors.middleInitial}
                    helperText={form.formState.errors.middleInitial?.message}/>}
                />
              </Grid>
              <Grid  item md={4} xs={12} className={classes.gridItem}>
                <Controller name="lastName"
                  control={form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} 
                    label="Last Name"
                    fullWidth={true}
                    error={!!form.formState.errors.lastName}
                    helperText={form.formState.errors.lastName?.message}/>}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller name="percentage"
                  control={form.control}
                  rules={{ required: 'This field is required' }}
                  defaultValue={undefined}
                  render={({ field }) => <TextField {...field} 
                    label="Percent"
                    type="number"
                    inputProps={{ min: "00.1", step: "0.01" }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    fullWidth={true}
                    error={!!form.formState.errors.percentage}
                    helperText={form.formState.errors.percentage?.message}
                    />}
                />
              </Grid>
            </>
            }

        </Grid>
        </CardContent>
        <Divider />
        <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit" disabled={!relationship}>
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}