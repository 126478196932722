import { createContext, useContext, useReducer, useCallback, useEffect } from 'react';
import firebase from '../lib/firebase';
import Bugsnag from '../lib/bugsnag';

export type IntakeType = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  addressLine1:string;
  addressLine2:string;
  city: string;
  state: string;
  zipcode: string;

  ownOrRent: 'own'|'rent';
  netWorth: string;
  maritalStatus: 'single' | 'married' | 'separated' | 'divorced' | 'widowed';
  hasChildren: 'yes' | 'no';

  children: ChildInfoType[],
  cashBequests: CashBequestType[],
  beneficiaries: Beneficiary[],

  personalRepresentative: RelationshipOrPersonType,
  personalRepresentativeSuccessor: RelationshipOrPersonType,

  childGuardian: PersonType,
  childGuardianSuccessor: PersonType,

  healthcarePreference: 'MaximumComfort' | 'ExtraordinaryMeasures',
  
  healthcareProxy: RelationshipOrPersonType,
  healthcareProxySuccessor: RelationshipOrPersonType,
  healthcareProxyLastResort: RelationshipOrPersonType,

  
  financialProxy: RelationshipOrPersonType,
  financialProxySuccessor: RelationshipOrPersonType,
  financialProxyLastResort: RelationshipOrPersonType
}
export type ChildInfoType = {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date | '';
}

export type CashBequestType = {
  firstName: string;
  middleInitial: string;
  lastName: string;
  amount: number;
}

export type PersonType = {
  firstName: string,
  middleInitial: string,
  lastName: string,
  addressLine1:string;
  addressLine2:string;
  city: string;
  state: string;
  zipcode: string;
}

export interface RelationshipOrPersonType extends PersonType {
  relationship: 'spouse' | 'child' | 'other',
  childName: string,
}
export interface Beneficiary extends RelationshipOrPersonType {
  percentage: number
}

interface IntakeContextInit {
  currentIntake: IntakeType,
  // updateFormData: Dispatch<SetStateAction<IntakeType>>,
  saveIntake: (i: Partial<IntakeType>) => any
}
const IntakeContext = createContext<IntakeContextInit | undefined>(undefined);

const reducer = (state: any, update: any) : any => {
  console.log('calling reducer')
  return {
    ...state,
    ...update
  }
}

export const IntakeProvider = (props : any) => {
  // this state will be shared with all components 
  const [currentIntake, updateCurrentIntake] = useReducer(reducer, {});

  const getIntake = useCallback(async () => {
    const idToken = await firebase.auth().currentUser?.getIdToken();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENDPOINT_INTAKE}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      const intake = await response.json();
      updateCurrentIntake(intake);
    } catch (error: any) {
      Bugsnag.notify(error);
    }
  }, [updateCurrentIntake]);
  useEffect(() => {
    getIntake();
  }, [getIntake]);

  const saveIntake = useCallback(async (data) => {
    const idToken = await firebase.auth().currentUser?.getIdToken();
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ENDPOINT_INTAKE}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(data)
      });
      const intake = await response.json();

      updateCurrentIntake(intake);
    } catch (ex) {
      Bugsnag.notify(ex);
    }
  }, [updateCurrentIntake]);

  return (
    <IntakeContext.Provider value={{currentIntake, saveIntake}}>
      {props.children}
    </IntakeContext.Provider>
  );
};

export const useFormData = () => {
  const context = useContext(IntakeContext);

  if (!context) {
    throw new Error('useFormData must be used within a IntakeContext');
  }

  return context;
}