import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from '../lib/firebase';
import Bugsnag from '../lib/bugsnag';
import { LS_LOGIN_EMAIL } from '../constants/keys';
import { UserContext } from '../context/UserContext';
import { Box, CircularProgress } from '@material-ui/core';
import { Paths } from '../constants/paths';
import { useCallback } from 'react';

export const LoginCallback = () => {
  const history = useHistory();
  const [user, setUser] = useContext(UserContext);

  

  // The redirect contains a `provider` query param if the user is logging in with a social provider
  const callback = useCallback(async() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem(LS_LOGIN_EMAIL);
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }

      // The client SDK will parse the code from the link for you.
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      firebase.auth().signInWithEmailLink(email!, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem(LS_LOGIN_EMAIL);
        setUser(result.user?.uid);
        history.replace(Paths.PersonalInfo1);
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        Bugsnag.notify(error);
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
    }
  }, [history, setUser]);

  useEffect(() => {
    callback();
  });


  return (
    <Box mt="100px" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress size="10rem" />
    </Box>
  )
};
