import { useParams, useHistory } from "react-router-dom"
import { Controller, useForm } from "react-hook-form";
import { FormComponentProps } from '../FormComponentProps';
import { Button, Grid, TextField, Card, CardHeader, CardContent, Divider, Box } from '@material-ui/core';
import { ChildInfoType } from "../../context/IntakeContext";
import { Paths } from '../../constants/paths';
import { useStyles } from '../styles';
import { useFormData } from '../../context/IntakeContext';

export default function SingleChildInfo(props: FormComponentProps) {
  const { currentIntake, saveIntake } = useFormData();
  const classes = useStyles();
  
  let { idx } = useParams() as any;
  const zeroIndex = parseInt(idx, 10) - 1;
  const currentValues = ((currentIntake.children ?? [])[zeroIndex] || {});

  const form = useForm<ChildInfoType>({
    defaultValues: currentValues
  });

  const history = useHistory();

  const onSubmit = form.handleSubmit(async (data) => {
    if (!currentIntake.children) {
      currentIntake.children = [];
    }

    currentIntake.children[zeroIndex] = data;
    
    await saveIntake({children: currentIntake.children});
    history.push(Paths.ChildrenInfo);
  });

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title="About you"
          subheader="Let's start by getting your basic information"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid item md={4} className={classes.gridItem}>
              <Controller name="firstName"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="First Name"
                fullWidth={true}
                error={!!form.formState.errors.firstName}
                helperText={form.formState.errors.firstName?.message}/>
              }/>
            </Grid>
            <Grid item md={4} className={classes.gridItem}>
              <Controller name="middleName"
              control={form.control}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Middle Name"
                fullWidth={true}
                error={!!form.formState.errors.middleName}
                helperText={form.formState.errors.middleName?.message}/>
              }/>
            </Grid>
            <Grid item md={4} className={classes.gridItem}>
              <Controller name="lastName"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Last Name"
                fullWidth={true}
                error={!!form.formState.errors.lastName}
                helperText={form.formState.errors.lastName?.message}/>
              }/>
            </Grid>
            <Grid item md={12} className={classes.gridItem}>
              <Controller name="dateOfBirth"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Date of Birth"
                type="date"
                fullWidth={true}
                error={!!form.formState.errors.dateOfBirth}
                helperText={form.formState.errors.dateOfBirth?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              }/>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}