import { FormComponentProps } from '../FormComponentProps';
import { makeStyles, Box, Button, Card, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { Paths } from '../../constants/paths';
import { useTheme } from '@material-ui/core/styles';
import ListWrapper from '../GenericList/ListWrapper';
import { useStyles as globalStyles } from '../styles';
import { FaRegTrashAlt, FaEdit } from 'react-icons/fa';
import { useFormData } from '../../context/IntakeContext';

export default function ChildrenList(props: FormComponentProps) {
  const { currentIntake, saveIntake } = useFormData();
  const history = useHistory();
  const theme = useTheme();

  const useStyles = makeStyles({
    outerBox: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%'
    },
    tableWrapper: {
      minWidth: 1050
    }
  });
  const classes = Object.assign({}, useStyles(), globalStyles());

  const addChild = () => {
    const nextChildId = currentIntake.children ? currentIntake.children.length + 1 : 1;
    history.push(`${Paths.ChildrenInfo}/${nextChildId}`)
  }
  const editChild = (idx: number) => {
    history.push(`${Paths.ChildrenInfo}/${idx+1}`);
  }

  const finish = () => {
    history.push(Paths.CashBequests)
  }

  const removeChild = async (idx : number) => {
    currentIntake.children.splice(idx, 1);
    await saveIntake({ children: currentIntake.children });
  }

  const children = currentIntake.children || [];

  return (
    <ListWrapper addText="Add Child" add={addChild} finishText="Finished Adding Children" finish={finish}>
              <Box pt={3}>
          <Card>
            <Box className={classes.tableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Date of Birth
                    </TableCell>
                    <TableCell>

                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {children.map((child, idx) => (
                    <TableRow
                      hover
                      key={idx}>
                      <TableCell>
                        {child.firstName} {child.middleName} { child.lastName }
                      </TableCell>
                      <TableCell>
                        {child.dateOfBirth}
                      </TableCell>
                      <TableCell align="right">
                          <Button onClick={() => { editChild(idx); } }><FaEdit /></Button>
                          <Button onClick={() => { removeChild(idx); } }><FaRegTrashAlt /></Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Box>
    </ListWrapper>
    
  )
}