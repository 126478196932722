import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, InputLabel, TextField, Select, MenuItem , Button, FormControl, Card, CardHeader, CardContent, Box, Divider } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { FormComponentProps } from './FormComponentProps';
import { states } from '../constants';
import { PersonType } from '../context/IntakeContext';
import { useStyles } from './styles';
import { useFormData } from '../context/IntakeContext';
interface PersonProps extends FormComponentProps {
  dataKey: 'childGuardian' | 'childGuardianSuccessor';
  nextPath: string,
  header: string,
}

export default function Person(props: PersonProps) {
  const classes = useStyles();
  const { currentIntake, saveIntake } = useFormData();

  const form = useForm<PersonType>({
    defaultValues: currentIntake[props.dataKey]
  });
  
  const history = useHistory();

  const onSubmit = form.handleSubmit(async (data) => {
    await saveIntake({[props.dataKey]: data});
    history.push(props.nextPath);
  });

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title={props.header}
          // subheader="Let's start by getting your basic information"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid item xs={4}>
              <Controller name="firstName"
                control={form.control}
                rules={{ required: 'This field is required'}}
                defaultValue=""
                render={({ field }) => <TextField {...field} 
                  label="First Name"
                  fullWidth={true}
                  error={!!form.formState.errors.firstName}
                  helperText={form.formState.errors.firstName?.message}/>}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller name="middleInitial"
                control={form.control}
                defaultValue=""
                render={({ field }) => <TextField {...field} 
                  fullWidth={true}
                  label="Middle Initial"
                  error={!!form.formState.errors.middleInitial}
                  helperText={form.formState.errors.middleInitial?.message}/>}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller name="lastName"
                control={form.control}
                rules={{ required: 'This field is required'}}
                defaultValue=""
                render={({ field }) => <TextField {...field} 
                  label="Last Name"
                  fullWidth={true}
                  error={!!form.formState.errors.lastName}
                  helperText={form.formState.errors.lastName?.message}/>}
              />
            </Grid>
      
            <Grid item xs={12}>
            <Controller name="addressLine1"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Address"
                fullWidth={true}
                error={!!form.formState.errors.addressLine1}
                helperText={form.formState.errors.addressLine1?.message}/>}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller name="addressLine2"
              control={form.control}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Address (line 2)"
                fullWidth={true}
                error={!!form.formState.errors.addressLine2}
                helperText={form.formState.errors.addressLine2?.message}/>}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller name="city"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="City"
                fullWidth={true}
                error={!!form.formState.errors.city}
                helperText={form.formState.errors.city?.message}/>}
            />
          </Grid>
          <Grid item xs={4}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="state">State</InputLabel>
            <Controller name="state"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <Select
                
                {...field} 
                fullWidth={true}
                error={!!form.formState.errors.state}
                value={field.value || ''}>
                {states.map(state => {
                  return (<MenuItem key={state} value={state}>{state}</MenuItem>)
                })}
              </Select>}
            />
          </FormControl>
            
          </Grid>
          <Grid item xs={4}>
            <Controller name="zipcode"
              control={form.control}
              rules={{ required: 'This field is required', pattern: { value: /^\d{5}$/, message: 'Zipcode must be 5 digits'}}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Zipcode"
                type="number"
                fullWidth={true}
                error={!!form.formState.errors.zipcode}
                helperText={form.formState.errors.zipcode?.message}/>}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />

      <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}