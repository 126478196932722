import { FormComponentProps } from '../FormComponentProps';
import { makeStyles, Box, Card, Table, TableHead, TableRow, TableBody, TableCell, Button } from '@material-ui/core';

import { FaRegTrashAlt, FaEdit } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { Paths } from '../../constants/paths';
import { useStyles as globalStyles } from '../styles';
import ListWrapper from '../GenericList/ListWrapper';
import { useTheme } from '@material-ui/core/styles';
import { useFormData } from '../../context/IntakeContext';

export default function CashBequestsList(props: FormComponentProps) {
  const { currentIntake, saveIntake } = useFormData();
  const history = useHistory();
  const theme = useTheme();

  const useStyles = makeStyles({
    outerBox: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%'
    },
    tableWrapper: {
      minWidth: 1050
    }
  });
  const classes = Object.assign({}, useStyles(), globalStyles());

  const bequests = currentIntake.cashBequests || [];

  const addBequest = () => {
    const nextBequestId = currentIntake.cashBequests ? currentIntake.cashBequests.length + 1 : 1;
    history.push(`${Paths.CashBequests}/${nextBequestId}`)
  }
  const editBequest = (idx : number) => {
    history.push(`${Paths.CashBequests}/${idx + 1}`)
  }

  const removeBequest = async (idx : number) => {
    currentIntake.cashBequests.splice(idx, 1);
    await saveIntake({ cashBequests: currentIntake.cashBequests });
  }

  const finish = () => {
    history.push(Paths.Beneficiaries)
  }

  return (
    <ListWrapper addText="Add Bequest" add={addBequest} finishText="Finished Adding Bequests" finish={finish}>
        <Box pt={3}>
          <Card>
            <Box className={classes.tableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Amount to Bequeath
                    </TableCell>
                    <TableCell>

                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bequests.map((beq, idx) => (
                    <TableRow
                      hover
                      key={idx}>
                      <TableCell>
                        {beq.firstName} {beq.middleInitial} { beq.lastName }
                      </TableCell>
                      <TableCell>
                        ${beq.amount}
                      </TableCell>
                      <TableCell align="right">
                          <Button onClick={() => { editBequest(idx); } }><FaEdit /></Button>
                          <Button onClick={() => { removeBequest(idx); } }><FaRegTrashAlt /></Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Box>
    </ListWrapper>
  )
  // return (
  //   <>
  //   <Route path={Paths.CashBequests} exact={true}>
  //     <Typography variant="h3">Would you like to include any specific cash bequests?</Typography>
  //     <Typography variant="h5">Cash bequests are given out before your estate is divided by percentage.</Typography>

  //     <Grid container spacing={2}>
  //     {(props.data.cashBequests || []).map((bequest, idx) => (
      
  //       <>
  //       <Grid item xs={6}>{bequest.firstName} {bequest.middleInitial} { bequest.lastName } ${ bequest.amount }</Grid>
  //       <Grid item xs={3}><Button onClick={() => { history.push(`${Paths.CashBequests}/${idx+1}`)}} variant="contained" color="primary">Edit</Button></Grid>
  //       <Grid item xs={3}><Button onClick={() => { removeBequest(idx); }} variant="contained" color="secondary">Remove</Button></Grid>
  //       </>
  //     ))}


  //       <Button onClick={addBequest} variant="contained" color="primary">Add another Bequest</Button>
  //       <Button onClick={() => history.push(Paths.Beneficiaries)} variant="contained" color="secondary">Finished with bequests</Button>
  //     </Grid>
  //   </Route>
  //   <Route path={`${Paths.CashBequests}/:idx`} exact={true} render={(routeProps) => (
  //     <SingleCashBequest data={props.data} form={props.form} updateFormData={props.updateFormData} />
  //   )} />
  //   </>
  // )
}