import { Controller } from "react-hook-form";
import { Grid, InputLabel, Card, CardHeader, CardContent, Divider, Select, MenuItem, Button, Box, FormControl } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { netWorthOptions } from '../../constants';
import { FormComponentProps } from '../FormComponentProps';
import { Paths } from '../../constants/paths'
import { hasChildren } from '../../helpers/modelHelpers';
import { useStyles } from '../styles';
import { useFormData } from '../../context/IntakeContext';

export default function PersonalInfo2(props: FormComponentProps) {
  const { saveIntake } = useFormData();
  const history = useHistory();
  const classes = useStyles();

  const onSubmit = props.form.handleSubmit(async (data) => {
    if (!hasChildren(data)) {
      data.children = [];
    }
    await saveIntake(data);

    if (hasChildren(data)) {
      history.push(Paths.ChildrenInfo);
    } else {
      history.push(Paths.CashBequests);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title="Personal Info"
          // subheader="We need some more "
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="ownOrRent">Do you own or rent your personal residence?</InputLabel>
                <Controller name="ownOrRent"
                  control={props.form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue={undefined}
                  render={({ field }) => <Select {...field} fullWidth={true} error={!!props.form.formState.errors.ownOrRent} value={field.value || ''}>
                    <MenuItem key="own" value="own">Own</MenuItem>
                    <MenuItem key="rent" value="rent">Rent</MenuItem>
                  </Select>
                  }/>
                </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="netWorth">What is your net worth?</InputLabel>
                <Controller name="netWorth"
                  control={props.form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue={undefined}
                  render={({ field }) => <Select {...field} fullWidth={true} error={!!props.form.formState.errors.netWorth} value={field.value || ''}>
                    {netWorthOptions.map(nw => {
                      return (<MenuItem key={nw} value={nw}>{nw}</MenuItem>)
                    })}
                  </Select>
                  }/>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="maritalStatus">What is your marital status?</InputLabel>
                <Controller name="maritalStatus"
                  control={props.form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue={undefined}
                  render={({ field }) => <Select {...field} fullWidth={true} error={!!props.form.formState.errors.maritalStatus} value={field.value || ''}>
                    <MenuItem value="single">Single</MenuItem>
                    <MenuItem value="married">Married</MenuItem>
                    <MenuItem value="separated">Separated</MenuItem>
                    <MenuItem value="divorced">Divorced</MenuItem>
                    <MenuItem value="widowed">Widowed</MenuItem>
                  </Select>
                  }/>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="hasChildren">Do you have any children?</InputLabel>
                <Controller name="hasChildren"
                  control={props.form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue={undefined}
                  render={({ field }) => <Select {...field} fullWidth={true} error={!!props.form.formState.errors.hasChildren} value={field.value || ''}>
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                }/>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}