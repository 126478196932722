import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import { IconType } from 'react-icons'
import { useTheme } from '@material-ui/core/styles';

type navItemProps = {
  href: string,
  Icon: IconType,
  title: string,
  disabled?: boolean  
}
const NavItem = ({href, Icon, title, disabled, ...rest} : navItemProps) => {
  const theme = useTheme();
  const location = useLocation();

  const isActivePath = !!matchPath(location.pathname, {
    path: href,
    exact: false,
    strict: false
  });

  const useStyles = makeStyles({
    listItem: {
      display: 'flex',
      paddingTop: 0,
      paddintBottom: 0
    },
    button: {
      color: isActivePath ? theme.palette.primary.main : theme.palette.text.secondary,
      fontWeight: 500,
      justifyContent: 'flex-start',
      letterSpacing: 0,
      textTransform: 'none',
      width: '100%',
      '& svg': {
        mr: 1
      }
    },
    active: {
      color: theme.palette.primary.main,
    }
  })

  const classes = useStyles();


  return (
    
    <ListItem
      disableGutters
      className={classes.listItem}
      {...rest}
    >
      <Button
        component={RouterLink}
        className={classes.button}
        to={href}
        disabled={disabled}
      >
        {Icon && (
          <Icon size="20" style={{marginRight: '8px'}} />
        )}
        <span>
          {title}
        </span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
