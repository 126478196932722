import {
  Box,
  Button,
  Container,
  Divider,
  makeStyles
} from '@material-ui/core';
import { MouseEventHandler } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useStyles as globalStyles } from '../styles';

type ListWrapperProps = {
  addText: string,
  finishText: string,
  add: MouseEventHandler,
  finish: MouseEventHandler,
  children: any,
  finishDisabled?: boolean
}
const ListWrapper = (props: ListWrapperProps) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    outerBox: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%'
    },
    innerBox: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    tableWrapper: {
      minWidth: 1050
    }
  });

  const classes = Object.assign({}, useStyles(), globalStyles());
  return (
    <Box className={classes.outerBox}>
      <Container maxWidth={false}>
        {/* List Toolbar */}
        <Box>
          <Box className={classes.innerBox}>
            <Button
              color="secondary"
              variant="contained"
              onClick={props.add}>
              {props.addText}
            </Button>
          </Box>
        </Box>
        {/* End Toolbar */}
        { props.children }
      </Container>
      <Divider />
      <Box p={2} className={classes.buttonBox}>
        <Button color="primary" disabled={!!props.finishDisabled} variant="contained" type="submit" onClick={props.finish}>
          {props.finishText}
        </Button>
      </Box>
    </Box>
  )
};

export default ListWrapper;
