export const Paths = {
  PersonalInfo1: '/intake/personal/basic',
  PersonalInfo2: '/intake/personal/extra',
  ChildrenInfo: '/intake/children',
  CashBequests: '/intake/cash-bequests',
  Beneficiaries: '/intake/beneficiaries',
  PersonalRepresentative: '/intake/representative',
  PersonalRepresentativeSuccessor: '/intake/representative/successor',
  ChildGuardian: '/intake/guardian',
  ChildGuardianSuccessor: '/intake/guardian/successor',
  HealthcarePreference: '/intake/healthcare',
  HealthcareProxy: '/intake/healthcare/proxy',
  HealthcareProxySuccessor: '/intake/healthcare/proxy/successor',
  HealthcareProxyLastResort: '/intake/healthcare/proxy/last-resort',
  FinancialAgent: '/intake/financial-agent',
  FinancialAgentSuccessor: '/intake/financial-agent/successor',
  FinancialAgentLastResport: '/intake/financial-agent/last-resort',
  Conclusion: '/intake/conclusion'
  
}