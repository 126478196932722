import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import GlobalStyles from './components/GlobalStyles';
import Intake from './components/Intake';
import { UserContext } from './context/UserContext';
import { Login } from './pages/Login';
import { LoginCallback } from './pages/LoginCallback';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import theme from './theme';
import React from 'react';

function App() {
  const [user, setUser] = React.useState(null);
  return (
    <UserContext.Provider value={[user, setUser]}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Route path="/login" component={Login} />
          <Route path="/callback" component={LoginCallback} />
          <Route path="/intake" component={Intake}/>
          <Route path="/" exact={true} >
            { user ? <Redirect to="/intake" /> : <Redirect to="/login" /> }
          </Route>
        </Router>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;
