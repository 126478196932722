import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, InputLabel, TextField, Select, MenuItem , Button, Card, CardHeader, Divider, CardContent, Box, FormControl } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { FormComponentProps } from './FormComponentProps';
import { RelationshipOrPersonType } from '../context/IntakeContext';
import { hasSpouse, hasChildren, childFullName } from '../helpers/modelHelpers';
import { useStyles } from './styles';
import { useFormData } from '../context/IntakeContext';
interface RelationshipOrPersonProps extends FormComponentProps {
  dataKey: 'personalRepresentative' | 'personalRepresentativeSuccessor' | 'healthcareProxy' | 'healthcareProxySuccessor' | 'healthcareProxyLastResort' | 'financialProxy' | 'financialProxySuccessor' | 'financialProxyLastResort';
  nextPath: string,
  header: string
}

export default function RelationshipOrPerson(props: RelationshipOrPersonProps) {

  const { currentIntake, saveIntake } = useFormData();
  
  currentIntake[props.dataKey] = currentIntake[props.dataKey] || {};


  const form = useForm<RelationshipOrPersonType>({
    defaultValues: currentIntake[props.dataKey]
  });
  
  const history = useHistory();

  const onSubmit = form.handleSubmit(async (data) => {
    await saveIntake({ [props.dataKey]: data });
    history.push(props.nextPath);
  });

  const hasSpouseOrChildren = hasSpouse(currentIntake) || hasChildren(currentIntake);
  let calculatedRelationship = '' as '' | 'spouse' | 'child' | 'other';
  if (!hasSpouseOrChildren) {
    calculatedRelationship = 'other';
  }

  const [relationship, setRelationship] = useState(calculatedRelationship);
  const handleRelationshipChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRelationship(event.target.value as 'spouse' | 'child' | 'other');
  }

  const classes = useStyles();
  
  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title={props.header}
          // subheader="Let's start by getting your basic information"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            {hasSpouseOrChildren &&
              <Grid item md={12} className={classes.gridItem}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="chooseRelationship">Relationship...</InputLabel>
                  <Select name="chooseRelationship" fullWidth={true} onChange={handleRelationshipChange} value={relationship}>
                    { hasSpouse(currentIntake) && 
                    <MenuItem value="spouse">My Spouse</MenuItem>
                    }
                    { hasChildren(currentIntake) && 
                    <MenuItem value="child">My Child</MenuItem>
                    }
                    <MenuItem value="other">Someone Else</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            }

            { (relationship === 'child') &&
              <>
              <input type="hidden" value="child" {...form.register("relationship")} />
              <Grid item md={12} className={classes.gridItem}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="childName">Which Child?</InputLabel>
                  <Controller
                    name="childName"
                    control={form.control}
                    rules={{ required: 'This field is required'}}
                    defaultValue={undefined}
                    render={({ field }) => <Select {...field} fullWidth={true} error={!!form.formState.errors.childName} value={field.value || ''}>
                      {
                      (currentIntake.children || []).map(child => { return (
                        <MenuItem value={childFullName(child)}>{childFullName(child)}</MenuItem>)
                      }) 
                      }
                    </Select>
                  }/>
                </FormControl>
              </Grid>
              </>
            }
            { (relationship === 'other') && 
            <>
              <input type="hidden" value="other" {...form.register("relationship")} />
              <Grid  item md={4} xs={12} className={classes.gridItem}>
                <Controller name="firstName"
                  control={form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} 
                    label="First Name"
                    fullWidth={true}
                    error={!!form.formState.errors.firstName}
                    helperText={form.formState.errors.firstName?.message}/>}
                />
              </Grid>
              <Grid  item md={4} xs={12} className={classes.gridItem}>
                <Controller name="middleInitial"
                  control={form.control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field}
                    label="Middle Initial" 
                    fullWidth={true}
                    error={!!form.formState.errors.middleInitial}
                    helperText={form.formState.errors.middleInitial?.message}/>}
                />
              </Grid>
              <Grid  item md={4} xs={12} className={classes.gridItem}>
                <Controller name="lastName"
                  control={form.control}
                  rules={{ required: 'This field is required'}}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} 
                    label="Last Name"
                    fullWidth={true}
                    error={!!form.formState.errors.lastName}
                    helperText={form.formState.errors.lastName?.message}/>}
                />
              </Grid>
            </>
            }

          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit" disabled={!relationship}>
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}