import { useParams, useHistory } from "react-router-dom"
import { Controller, useForm } from "react-hook-form";
import { FormComponentProps } from '../FormComponentProps';
import { makeStyles, Button, Grid, TextField, Card, CardHeader, Divider, CardContent, Box, InputAdornment } from '@material-ui/core';
import { CashBequestType } from "../../context/IntakeContext";
import { useStyles as globalStyles } from '../styles';
import { useTheme } from '@material-ui/core/styles';
import { Paths } from '../../constants/paths';
import { useFormData } from '../../context/IntakeContext';

export default function SinglCashBequest(props: FormComponentProps) {
  const { currentIntake, saveIntake } = useFormData();
  let { idx } = useParams() as any;
  const zeroIndex = parseInt(idx, 10) - 1;
  const currentValues = ((currentIntake.cashBequests ?? [])[zeroIndex] || {});

  const form = useForm<CashBequestType>({
    defaultValues: currentValues
  });

  const history = useHistory();

  const theme = useTheme();
  const useStyles = makeStyles({
    outerBox: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%'
    },
    tableWrapper: {
      minWidth: 1050
    }
  });
  
  const classes = Object.assign({}, useStyles(), globalStyles());

  const onSubmit = form.handleSubmit(async (data) => {
    if (!currentIntake.cashBequests) {
      currentIntake.cashBequests = [];
    }

    currentIntake.cashBequests[zeroIndex] = data;
    
    await saveIntake({ cashBequests: currentIntake.cashBequests });
    history.push(Paths.CashBequests);
  });
  

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title="Who would you like to bequeath?"
          // subheader="Let's start by getting your basic information"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid item md={4} className={classes.gridItem}>
              <Controller name="firstName"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="First Name"
                fullWidth={true}
                error={!!form.formState.errors.firstName}
                helperText={form.formState.errors.firstName?.message}/>
              }/>
            </Grid>
            <Grid item md={4} className={classes.gridItem}>
              <Controller name="middleInitial"
              control={form.control}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Middle Initial"
                fullWidth={true}
                error={!!form.formState.errors.middleInitial}
                helperText={form.formState.errors.middleInitial?.message}/>
              }/>
            </Grid>
            <Grid item md={4} className={classes.gridItem}>
              <Controller name="lastName"
              control={form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <TextField {...field} 
                label="Last Name"
                fullWidth={true}
                error={!!form.formState.errors.lastName}
                helperText={form.formState.errors.lastName?.message}/>
              }/>
            </Grid>
            <Grid item md={12} className={classes.gridItem}>
              <Controller name="amount"
              control={form.control}
              rules={{ required: 'This field is required' }}
              // defaultValue={undefined}
              render={({ field }) => <TextField {...field} 
                label="Amount to Bequeath"
                type="number"
                inputProps={{ min: "00.1", step: "0.01" }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                fullWidth={true}
                error={!!form.formState.errors.amount}
                helperText={form.formState.errors.amount?.message}
                />
              }/>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}
/*  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel htmlFor="firstName">First Name</InputLabel>
          <Controller name="firstName"
            control={form.control}
            rules={{ required: 'This field is required'}}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              fullWidth={true}
              error={!!form.formState.errors.firstName}
              helperText={form.formState.errors.firstName?.message}/>}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel htmlFor="middleInitial">Middle Initial</InputLabel>
          <Controller name="middleInitial"
            control={form.control}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              fullWidth={true}
              error={!!form.formState.errors.middleInitial}
              helperText={form.formState.errors.middleInitial?.message}/>}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel htmlFor="lastName">Last Name</InputLabel>
          <Controller name="lastName"
            control={form.control}
            rules={{ required: 'This field is required'}}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              fullWidth={true}
              error={!!form.formState.errors.lastName}
              helperText={form.formState.errors.lastName?.message}/>}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="amount">Amount to bequeth</InputLabel>
          <Controller name="amount"
            control={form.control}
            rules={{ required: 'This field is required' }}
            defaultValue={undefined}
            render={({ field }) => <TextField {...field} 
              type="number"
              inputProps={{ min: "00.1", step: "0.01" }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              fullWidth={true}
              error={!!form.formState.errors.amount}
              helperText={form.formState.errors.amount?.message}
              />}
          />
        </Grid>
        <Grid item xs={4}>
          <Button type="submit" variant="contained" color="primary">Save</Button>
        </Grid>
      </Grid>
    </form>
  )
}*/