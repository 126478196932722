import { Paths } from '../constants/paths';
import { useFormData } from '../context/IntakeContext';
import { hasChildren } from '../helpers/modelHelpers';

import {
  makeStyles,
  Box,
  Drawer,
  Hidden,
  List
} from '@material-ui/core';

import { FaUser, FaHome, FaChild, FaWallet, FaMoneyBillWave, FaHandsHelping, FaHospital } from 'react-icons/fa';
import { RiParentFill } from 'react-icons/ri';
import { GoLaw } from 'react-icons/go';

import NavItem from './NavItem';

const useStyles = makeStyles({
  box1: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  box2: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  box4: {
    flexGrow: 1
  },
  box5: {
    backgroundColor: 'background.default',
  },
  box6: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawer1: {
    width: 256
  },
  drawer2: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
});

const IntakeSidebar = () => {
  const { currentIntake } = useFormData();

  const classes = useStyles();

  const content = (
    <Box className={classes.box1}>
      <Box p={2}>
        <List>
        <NavItem
          href={Paths.PersonalInfo1}
          title="About You"
          Icon={FaUser} />
        <NavItem
          href={Paths.PersonalInfo2}
          title="Personal Information"
          Icon={FaHome} />
        {hasChildren(currentIntake) && 
          <NavItem
          href={Paths.ChildrenInfo}
          title="Your Children"
          Icon={FaChild} />
        }
        <NavItem
          href={Paths.CashBequests}
          title="Cash Bequests"
          Icon={FaWallet}
          disabled={!currentIntake.hasChildren}
          />
        <NavItem
          href={Paths.Beneficiaries}
          title="Beneficiaries"
          Icon={FaMoneyBillWave}
          disabled={!currentIntake.hasChildren}
          />

        <NavItem
          href={Paths.PersonalRepresentative}
          title="Personal Representative"
          Icon={GoLaw}
          disabled={!currentIntake.hasChildren}
          />

        {hasChildren(currentIntake) && 
          <NavItem
          href={Paths.ChildGuardian}
          title="Child Guardian"
          Icon={RiParentFill}
          disabled={!currentIntake.hasChildren} />
        }
        <NavItem
          href={Paths.HealthcarePreference}
          title="Health Decisions"
          Icon={FaHospital}
          disabled={!currentIntake.hasChildren} />
        <NavItem
          href={Paths.FinancialAgent}
          title="Financial Agent"
          Icon={FaHandsHelping}
          disabled={!currentIntake.hasChildren} />

        </List>

      </Box>
    </Box>
  );

  return (
    <>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            className: classes.drawer2
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default IntakeSidebar;
