import { Box, Container, Typography, Button } from '@material-ui/core';
import { TextField } from '../mui/TextField';
import { Controller, useForm } from "react-hook-form";
import { UserContext } from '../context/UserContext';
import { LS_LOGIN_EMAIL } from '../constants/keys';
import firebase from '../lib/firebase';
import Bugsnag from '../lib/bugsnag';
import React from 'react';

export const Login = () => {
  
  // const [user, setUser] = React.useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const form = useForm({
    defaultValues: { email: '' }
  });

  const onSubmit = form.handleSubmit(async ({email}) => {
    setIsSubmitting(true);

    try {

      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: new URL('/callback', window.location.origin).href,
        // This must be true.
        handleCodeInApp: true
      };
      await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem(LS_LOGIN_EMAIL, email);
      })
      .catch((error : Error) => {
        Bugsnag.notify(error);
      });

    } catch (error) {
      setIsSubmitting(false);
      Bugsnag.notify(error);
    }
  });

  return (
    <>
    <Box style={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
    {!isSubmitting && (
    <Container maxWidth="sm">
      <form onSubmit={onSubmit}>
        <Box pb={1} pt={3}>
          <Typography align="center" color="textPrimary" variant="h2">Enter your email address to get started</Typography>
          {/* <Typography align="center" color="textSecondary" variant="h2">Enter your email address to get started</Typography> */}
        </Box>

          <Controller name="email"
            control={form.control}
            rules={ {required: 'This field is required', pattern: { value: /^\S+@\S+$/, message: 'Email address is invalid' }} }
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="Email Address"
              fullWidth={true}
              error={!!form.formState.errors.email}
              helperText={form.formState.errors.email?.message}
              autoComplete="email"

            />
            } />

          <Box py={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              Send Magic Link
            </Button>
          </Box>
      </form>
    </Container>
    )}

    {isSubmitting && (
      <Container maxWidth="sm">
        <Box pb={1} pt={3}>
        <Typography align="center" color="textPrimary" variant="h2">We sent a link to login to the email address you provided. Please click the link to proceed.</Typography>
        </Box>
      </Container>
    )}
    </Box>
</>
)
}

