import { styled } from '@material-ui/core/styles';
import IntakeSidebar from './IntakeSidebar';
import IntakeNavbar from './IntakeNavbar';
import Form from '../FormComponents/Form';

const IntakeLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const IntakeLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const IntakeLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const IntakeLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  return (
    <IntakeLayoutRoot>
      <IntakeNavbar />
      <IntakeSidebar />
      <IntakeLayoutWrapper>
        <IntakeLayoutContainer>
          <IntakeLayoutContent>
            <Form />
          </IntakeLayoutContent>
        </IntakeLayoutContainer>
      </IntakeLayoutWrapper>
    </IntakeLayoutRoot>
  );
};

export default DashboardLayout;
