import { FormComponentProps } from '../FormComponentProps';
import { makeStyles, Box, Card, Table, TableHead, TableRow, TableBody, TableCell, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FaRegTrashAlt, FaEdit } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { Paths } from '../../constants/paths';
import { useStyles as globalStyles } from '../styles';
import ListWrapper from '../GenericList/ListWrapper';
import { useTheme } from '@material-ui/core/styles';
import { useFormData } from '../../context/IntakeContext';

export default function BeneficiariesList(props: FormComponentProps) {
  const { currentIntake, saveIntake } = useFormData();
  const history = useHistory();
  const theme = useTheme();

  const useStyles = makeStyles({
    outerBox: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%'
    },
    tableWrapper: {
      minWidth: 1050
    }
  });
  const classes = Object.assign({}, useStyles(), globalStyles());

  const beneficiaries = currentIntake.beneficiaries || [];

  const addBeneficiary = () => {
    const nextId = currentIntake.beneficiaries ? currentIntake.beneficiaries.length + 1 : 1;
    history.push(`${Paths.Beneficiaries}/${nextId}`)
  }

  const editBeneficiary = (idx : number) => {
    history.push(`${Paths.Beneficiaries}/${idx + 1}`)
  }

  const removeBeneficiary = async (idx : number) => {
    currentIntake.beneficiaries.splice(idx, 1);
    await saveIntake({ beneficiaries: currentIntake.beneficiaries });
  }

  const finish = () => {
    history.push(Paths.PersonalRepresentative)
  }

  const totalPercentage = beneficiaries.reduce((acc: number, currentVal) => {
    return acc + parseInt(`${currentVal.percentage}`, 10);
  }, 0);

  return (
    <ListWrapper addText="Add Beneficiary" add={addBeneficiary} finishText="Finished Adding Beneficiaries" finish={finish} finishDisabled={totalPercentage !== 100}>
        <Box pt={3}>
          { totalPercentage !== 100 &&
          <Card>
            <Alert severity="warning">
              Total allocation must add up to 100% <strong>(currently at {totalPercentage}%)</strong>
            </Alert>
          </Card>
          } 
        </Box>
        <Box pt={3}>
          <Card>
            <Box className={classes.tableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Amount to Bequeath
                    </TableCell>
                    <TableCell>

                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beneficiaries.map((ben, idx) => (
                    <TableRow
                      hover
                      key={idx}>
                      <TableCell>
                        {ben.relationship === 'spouse' && `Spouse`}
                        {ben.relationship === 'child' && `${ben.childName}`}
                        {ben.relationship === 'other' && `${ben.firstName} ${ben.middleInitial} ${ben.lastName}`}
                      </TableCell>
                      <TableCell>
                        ${ben.percentage}%
                      </TableCell>
                      <TableCell align="right">
                          <Button onClick={() => { editBeneficiary(idx); } }><FaEdit /></Button>
                          <Button onClick={() => { removeBeneficiary(idx); } }><FaRegTrashAlt /></Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Box>
    </ListWrapper>
  )
}