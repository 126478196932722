// import { React } from 'react';
import { useHistory } from "react-router-dom";
import { Paths } from "../constants/paths";
import { FormComponentProps } from "./FormComponentProps"
import { FormControl, FormControlLabel, FormLabel, FormHelperText, Box, Grid, Button, Card, CardHeader, Divider, CardContent, RadioGroup, Radio } from '@material-ui/core';
import { useStyles } from './styles';
import { Controller } from "react-hook-form";
import { useFormData } from '../context/IntakeContext';

export default function HealthcarePreferences(props: FormComponentProps) {
  const { saveIntake } = useFormData();
  const classes = useStyles();
  const history = useHistory();


  const onSubmit = props.form.handleSubmit(async (data) => {
    await saveIntake({ healthcarePreference: data.healthcarePreference });
    history.push(Paths.HealthcareProxy);
  });
  
  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title="Healthcare Preferences"
          // subheader="If you are in critical condition without a reasonable likelihood of recovery, which would you prefer?"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap">
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <Controller name="healthcarePreference"
                control={props.form.control}
                rules={{ required: 'This field is required'}}
                defaultValue={undefined}
                render={({ field }) =>
                <FormControl component="fieldset" error={!!props.form.formState.errors.healthcarePreference}>
                  <FormLabel component="legend">If you are in critical condition without a reasonable likelihood of recovery, which would you prefer?</FormLabel>
                  <RadioGroup row aria-label="Healthcare Preference" 
                    {...field}>
                      <FormControlLabel value="MaximumComfort" control={<Radio />} label="Maximum Comfort and Pain Relief" />
                      <FormControlLabel value="ExtraordinaryMeasures" control={<Radio />} label="Extraordinary Measures to Prolong Life" />
                  </RadioGroup>
                  <FormHelperText>{props.form.formState.errors.healthcarePreference?.message}</FormHelperText>
                </FormControl>
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}