import { Controller } from "react-hook-form";
import { FormControl, Box, Grid, InputLabel, MenuItem , Button, Card, CardHeader, Divider, CardContent } from '@material-ui/core';
import { TextField } from '../../mui/TextField';
import { Select } from '../../mui/Select';
import { states } from '../../constants';
import { useHistory } from "react-router-dom";
import { FormComponentProps } from '../FormComponentProps';
import { Paths } from '../../constants/paths'
import { useStyles } from '../styles';
import { useFormData } from '../../context/IntakeContext';

export default function PersonalInfo1(props: FormComponentProps) {
  const { saveIntake } = useFormData();

  const classes = useStyles();
  const history = useHistory();

  const onSubmit = props.form.handleSubmit(async (data) => {
    await saveIntake(data);
    history.push(Paths.PersonalInfo2);
  });
  
  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader
          title="About you"
          subheader="Let's start by getting your basic information"
        />
        <Divider />
        <CardContent>
        <Grid container spacing={6} wrap="wrap">
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <Controller name="firstName"
            control={props.form.control}
            rules={{ required: 'This field is required'}}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="First Name"
              error={!!props.form.formState.errors.firstName}
              helperText={props.form.formState.errors.firstName?.message}
              autoComplete="given-name"
              />
            }/>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <Controller name="middleName"
            control={props.form.control}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="Middle Name"
              fullWidth={true}
              error={!!props.form.formState.errors.middleName}
              helperText={props.form.formState.errors.middleName?.message}
              autoComplete="additional-name"
              />
            }/>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <Controller name="lastName"
            control={props.form.control}
            rules={{ required: 'This field is required'}}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="Last Name"
              fullWidth={true}
              error={!!props.form.formState.errors.lastName}
              helperText={props.form.formState.errors.lastName?.message}
              autoComplete="family-name"
              />
            }/>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Controller name="email"
            control={props.form.control}
            rules={ {required: 'This field is required', pattern: { value: /^\S+@\S+$/, message: 'Email address is invalid' }} }
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="Email Address"
              fullWidth={true}
              error={!!props.form.formState.errors.email}
              helperText={props.form.formState.errors.email?.message}
              autoComplete="email"
            />
          }/>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Controller name="addressLine1"
            control={props.form.control}
            rules={{ required: 'This field is required'}}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="Address"
              fullWidth={true}
              error={!!props.form.formState.errors.addressLine1}
              helperText={props.form.formState.errors.addressLine1?.message}
              autoComplete="address-line1"
              />
            }/>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Controller name="addressLine2"
            control={props.form.control}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="Address (line 2)"
              fullWidth={true}
              error={!!props.form.formState.errors.addressLine2}
              helperText={props.form.formState.errors.addressLine2?.message}
              autoComplete="address-line2"
            />
          }/>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <Controller name="city"
            control={props.form.control}
            rules={{ required: 'This field is required'}}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="City"
              fullWidth={true}
              error={!!props.form.formState.errors.city}
              helperText={props.form.formState.errors.city?.message}
              autoComplete="address-level2"
              />
            }/>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="state">State</InputLabel>
            <Controller name="state"
              control={props.form.control}
              rules={{ required: 'This field is required'}}
              defaultValue=""
              render={({ field }) => <Select
                {...field} 
                fullWidth={true}
                error={!!props.form.formState.errors.state}
                autoComplete="address-level1"
                label="State"
                value={field.value || ''}>
                {states.map(state => {
                  return (<MenuItem key={state} value={state}>{state}</MenuItem>)
                })}
              </Select>
              }/>
          </FormControl>
          
        </Grid>
        <Grid item xs={12} md={4} className={classes.gridItem}>
          <Controller name="zipcode"
            control={props.form.control}
            rules={{ required: 'This field is required', pattern: { value: /^\d{5}$/, message: 'Zipcode must be 5 digits'}}}
            defaultValue=""
            render={({ field }) => <TextField {...field} 
              label="Zipcode"
              type="number"
              fullWidth={true}
              error={!!props.form.formState.errors.zipcode}
              helperText={props.form.formState.errors.zipcode?.message}
              autoComplete="postal-code"
              />
            }/>
        </Grid>
      </Grid>
        </CardContent>
        <Divider />
        <Box p={2} className={classes.buttonBox}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </Card>
    </form>
  )
}